import React, { useRef, useEffect } from 'react'
import { WrapperSection } from '../../wrappers'
import Img from 'gatsby-image'
import { T } from '../../typo'
import { s, colors, alpha } from '../../style'
import { ScrollDots } from '../../elements'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { ButtonText } from '../../buttons'
import { useOnScreen } from '../../hooks'
import ScrollContainer from 'react-indiana-drag-scroll'

const PortfolioMinis = ({ theme, data }) => {
  const { slug_section, title, language, button } = data
  const refProjects = useRef(null)
  const onScreen = useOnScreen(refProjects)

  const dataQ = useStaticQuery(graphql`
    {
      projects: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/content/projects/" } }
        sort: { fields: frontmatter___order, order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              title
              project_slug
              description
              order
              image {
                name
                childImageSharp {
                  fluid(
                    maxWidth: 640
                    quality: 100
                    toFormat: JPG
                    jpegProgressive: true
                  ) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setTimeout(() => refProjects.current.scrollTo({ left: 1 }), 0)
  }, [])

  const projects = dataQ.projects.edges.map(({ node }) => node.frontmatter)

  // const { width } = useWindowSize()

  return (
    <>
      <WrapperSection
        id={slug_section}
        extraCss={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          [s.md]: {
            marginBottom: '2rem',
          },
          contentVisibility: 'auto',
        }}>
        <T
          d={32}
          m={32}
          o={0.16}
          bold
          condensed
          upper
          extraCss={sHeadline}
          onScreen={onScreen}>
          {title}
        </T>
        {button && (
          <ButtonText
            language={language}
            theme={theme}
            button={button}
            o={0.3}
            extraCss={{ paddingRight: '2rem' }}>
            <T upper bold onScreen={onScreen}>
              {button?.text}
            </T>
          </ButtonText>
        )}
      </WrapperSection>
      <ScrollContainer
        nativeMobileScroll
        css={[
          sContainer,
          onScreen === false && {
            opacity: 0 /* contentVisibility: 'hidden' */,
          },
        ]}
        innerRef={refProjects}>
        {projects.map((project, id) => (
          <article
            key={id}
            css={[sProjectWrapper]}
            itemtype="http://schema.org/Article">
            <div css={sProject(theme)}>
              <div css={sImageContainer} className="portfolio-image-container">
                <Img
                  style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    borderRadius: 'inherit',
                  }}
                  fluid={project?.image?.childImageSharp?.fluid}
                  alt={project?.image?.name}
                />
              </div>
              <div css={sTextContainer} className="portfolio-text-container">
                <T
                  m={18}
                  d={18}
                  mb={0.5}
                  bold
                  condensed
                  extraCss={{
                    padding: '1.5rem 1.5rem 0',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                  }}>
                  {project.title}
                </T>
                <T
                  d={14}
                  xs={12}
                  sm={14}
                  mb={1}
                  extraCss={{
                    padding: '0 1.5rem',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    [s.xs]: {
                      WebkitLineClamp: 4,
                    },
                  }}>
                  {project.description}
                </T>
                {project.project_slug && (
                  <Link
                    to={`/${project.project_slug.toLowerCase().trim()}`}
                    css={sButton(theme)}>
                    SPRAWDŹ
                  </Link>
                )}
              </div>
            </div>
          </article>
        ))}
      </ScrollContainer>
      <ScrollDots
        onScreen={onScreen}
        refElement={refProjects}
        arr={projects}
        extraCss={sDots(theme)}
      />
    </>
  )
}

const sProjectWrapper = {
  height: 432,
  flexShrink: 0,
  padding: '2rem 1.5rem',
  [s.sm_down]: { width: '100vw' },
  [s.md]: { width: 600 },
  boxSizing: 'border-box',
  overflow: 'hidden',
}

const sProject = (theme) => ({
  transition: 'box-shadow 0.4s ease-out',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: colors[theme].background[0],
  borderRadius: '1.5rem',
  boxShadow: `0px 8px 16px ${colors.black.concat(alpha[4])}`,
  [s.touch]: {
    '.portfolio-text-container': {
      height: 232,
      opacity: 1,
    },
    '.portfolio-image-container': {
      height: 200,
    },
    '.portfolio-image-container-text, .portfolio-image-container-gradient': {
      opacity: 0,
    },
  },
  '&:hover': {
    [s.hover]: {
      boxShadow: `0px 8px 32px ${colors.black.concat(alpha[16])}`,
      '.portfolio-text-container': {
        height: 232,
        opacity: 1,
      },
      '.portfolio-image-container': {
        height: 200,
      },
      '.portfolio-image-container-text, .portfolio-image-container-gradient': {
        opacity: 0,
      },
    },
  },
})

const sContainer = {
  display: 'flex',
  overflowX: 'auto',
  [s.md]: { padding: '0rem 0rem rem' },
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
  transition: 'opacity 0.3s ease-in-out',
}

const sHeadline = {
  [s.sm_down]: {
    width: '100%',
    // textAlign: 'center',
    paddingLeft: '2rem',
    marginBottom: '0rem',
  },
  [s.md]: { padding: '0 2rem', marginBottom: '0rem' },
}

const sButton = (theme) => ({
  width: 'max-content',
  transition: 'background-color 0.25s ease-out, color 0.25s ease-out',
  display: 'flex',
  justifyContent: 'ceenter',
  alignItems: 'center',
  padding: '0.5rem 1.5rem',
  color: colors[theme].main,
  fontWeight: 700,
  borderRadius: '0 1.5rem 1.5rem 0',
  '&:hover': {
    [s.hover]: {
      color: colors.white,
      backgroundColor: colors[theme].main.concat(alpha[100]),
    },
  },
})

const sImageContainer = {
  transition: 'height 0.3s ease-out',
  position: 'relative',
  height: 400,
  borderRadius: '1.5rem 1.5rem 0 0',
  willChange: 'height',

  '.portfolio-image-container-text': {
    willChange: 'opacity',
    transition: 'opacity 0.3s ease-out',
    position: 'absolute',
    top: '90%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    fontSize: '1.5rem',
    maxWidth: '90%',
    fontWeight: 700,
    color: colors.white,
    opacity: 1,
    // textShadow: `0px 0px 16px ${colors.black.concat(alpha[30])}`,
  },
  '.portfolio-image-container-gradient': {
    willChange: 'opacity',
    transition: 'opacity 0.3s ease-out',
    opacity: 1,
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '50%',
    width: '100%',
    background: `linear-gradient(${colors.black.concat(
      alpha[0]
    )}, ${colors.black.concat(alpha[60])})`,
  },
}

const sTextContainer = {
  willChange: 'height',
  transition: 'height 0.3s ease-out, opacity 0.6s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  height: 0,
  opacity: 0,
}

const sDots = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '2rem 0',
    [s.xs]: {
      marginTop: 0,
    },
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: colors[theme].main,
  },
  inactive: {
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0.16,
    padding: `0 6px`,
    '&:hover': {
      [s.hover]: {
        opacity: 0.6,
      },
    },
  },
  active: {
    [s.sm_down]: {
      opacity: 1,
      '&:hover': {
        [s.hover]: {
          opacity: 1,
        },
      },
    },
  },
  activeDesktop: {
    [s.md]: {
      opacity: 1,
      '&:hover': {
        [s.hover]: {
          opacity: 1,
        },
      },
    },
  },
})

export default PortfolioMinis
